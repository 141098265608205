<template>
  <CreateOffer />
</template>

<script>
import CreateOffer from './Create';
export default {
  components: {
    CreateOffer,
  },
};
</script>

<style lang="scss" scoped></style>
